<template>
  <Tabs v-model="generateOrderListTab" :animated="false" class="tabs_styles">
    <TabPane :label="$t('key1005804')" name="pickingList" v-if="getPermission('wmsPicking_querySalesPicking_generateOrderList')">
      <generateOrderList v-if="generateOrderListTab === 'pickingList'"></generateOrderList>
    </TabPane>
    <TabPane :label="$t('key1005805')" name="pickingWave" v-if="getPermission('wmsPickingGoods_queryPickingGoodssupplier')">
      <pickingWave v-if="generateOrderListTab === 'pickingWave'"></pickingWave>
    </TabPane>
  </Tabs>
</template>

<script>
import generateOrderList from "./generateOrderList";
import pickingWave from "./pickingWave";
import Mixin from "@/components/mixin/common_mixin";


export default {
  name: "generateOrderListTab",
  mixins: [Mixin],
  data() {
    return {
      generateOrderListTab: 'pickingList'
    };
  },
  components: {
    generateOrderList,
    pickingWave
  }
};
</script>

<style lang="less" scoped>
.tabs_styles {
  /deep/ .ivu-tabs-bar {
    border-bottom: none;
    background-color: #fff;
    padding: 8px 0 3px 0;
    margin: 0;
  }
}
</style>
